import React from 'react';
import TitleH2 from '../../Titles/TitleH2';
import './error.css';

import errorPage from '../../../assets/error/page-error.svg';

function Error() {
  return (
    <div className="error">
      <div className="error--container">
        <img src={errorPage} alt="Page d'erreur Mcneapolis Web" />
        <TitleH2>
          Malheureusement, la page que vous recherchez n'existe pas..!
        </TitleH2>
      </div>
    </div>
  );
}

export default Error;
