import React from 'react';
import './portfolio.css';
import Button from '../../Button';
import DataTable from '../../../data';
import TitleH2 from '../../Titles/TitleH2';
import TitleH3 from '../../Titles/TitleH3';
import TitleH4 from '../../Titles/TitleH4';

import illustration from '../../../assets/images/my_bg_portfolio.svg';

function Portfolio() {
  return (
    <main>
      <div className="portfolio">
        <div className="portfolio-first--container">
          <div className="portfolio--content" data-aos="flip-up">
            <TitleH2>..Comment j'ai évolué ? </TitleH2>
            <p>
              ...Autodidacte, très passionné par les technologies de la
              programmation informatque depuis mon jeune âge, je me suis formé
              auprès de l'ESECAD, c'est une école qui est spécialiste dans la
              filière du commerce et de la formation aux métiers du Web. Elle
              fait partie du groupe Skill an You qui est le leader européen de
              la formation en ligne. Pendant toute ma formation, j'étais
              accompagné et encadré par de très bons formateurs informaticiens.
              Autrement, j'ai évolué et j'évolue toujours grâce à une sélection
              de plusieurs formations soit payantes, soit gratuites pour mieux
              maîtriser de nouveux langages.
            </p>
            <p>
              Je m'inspire, à chaque instant de tous les développeurs web que
              j'ai pu suivre comme par exemple Lior Shamla, Grafikart, Antho de
              Hardcoders, DonkeyGeek et Mathieu Gaston de H2PROG..etc.
              L'apprentissage du code ne s'arrête pas là, c'est un travail
              méthodique et organisé, que je mène quotidiennement pour mieux me
              documenter. Je me passionne pour les nouvelles technologies du
              web.
            </p>

            <p>
              Aujourd'hui, je suis certifié et confirmé OPQUAST dans la Maîtrise
              de la Qualité en Projet Web. L'équipe OPQUAST propose de
              nombreuses règles sous forme d'une Checklist informatique dont
              l'objectif est de certifier les compétences dédiée aux
              professionnels.
            </p>
            <Button page="/services">Mes Services</Button>
          </div>

          <div className="portfolio--illustration " data-aos="flip-up">
            <img src={illustration} alt="Illustration d'un espace de travail" />
          </div>
        </div>

        <div className="portfolio-second--container">
          <div className="progress-bar--container">
            <TitleH3>Mes Compétences</TitleH3>
            {DataTable[0].skills.map((skill, index) => {
              return (
                <div key={index} className="skills">
                  <p>{skill.name}</p>
                  <div
                    className="progress--bar html"
                    style={{
                      width: `${skill.description}`,
                      backgroundColor: `${skill.color}`,
                    }}
                  >
                    {skill.description}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="portfolio-third--container">
          <TitleH3>Mon Cursus, Mes Formations</TitleH3>
          <div className="curriculum--container">
            {DataTable[0].curriculum.length > 0 &&
              DataTable[0].curriculum.map(curriculum => {
                return (
                  <div
                    key={curriculum.id}
                    className="curriculum--item"
                    data-aos="zoom-in-down"
                  >
                    <TitleH4>{curriculum.content}</TitleH4>
                    <span>{curriculum.description}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Portfolio;
