import React from 'react';
import TitleH2 from '../../Titles/TitleH2';
import TitleH3 from '../../Titles/TitleH3';
import './notice.css';

import notice from '../../../assets/icons/notice.svg';

function LegalNotice() {
  return (
    <div className="notice" id="notice">
      <div className="notice--content">
        <div className="notice-content--text">
          <TitleH2>Mentions Légales McNEAPOLIS</TitleH2>
          <p>
            McNEAPOLIS est un site vitrine, il est construit par Mr Mohamed
            OUAILI dont il est le propriétaire, le concepteur, le développeur et
            l'intégrateur web. Ce site web est réalisé dont le seul objectif est
            de promouvoir les services de la micro-entreprise appartenant à Mr
            OUAILI. Tous les droits de la propriéte intellectuelle reviennent à
            son auteur.
          </p>
          <p>
            Le concepteur du site est conscient des enjeux de la protection des
            données personnelles. À ce titre, MCNEAPOLIS ne collecte aucune
            donnée privée de ses visiteurs, il ne fait pas usage de fichiers
            cookies. Il s'agit, en effet, d'une vitrine statique. Le statut du
            propriétaire est auto-entrepreneur (micro-entreprise), son numéro de
            son Siret est le suivant : 88054052100015.
          </p>
          <p>
            Dans le cadre du respect de la propriété intellectuelle, toutes les
            illustrations de ce site web sont extraites de &ldquo;
            https://storyset.com/technology &rdquo; dont le propriétaire du site
            web remercie son auteur et l'ensemble de ces développeurs.
          </p>
        </div>
        <div className="notice-content--image">
          <img src={notice} alt="Homme présentant un projet" />
        </div>
      </div>

      <div className="notice-first--container">
        <TitleH3>MCNEAPOLIS appartient à : </TitleH3>
        <ul className="notice-group">
          <li className="notice-item">Propriétaire : Mr Mohamed OUAILI</li>
          <li className="notice-item">
            Adresse : 13.Rue de la Gendarmerie 26230 Grignan, France
          </li>
          <li className="notice-item">Téléphone : (0033) 06 49 33 18 81</li>
        </ul>
        <TitleH3>Le site est hébergé par : </TitleH3>
        <ul className="notice-group">
          <li className="notice-item">Nom : LIGNE WEB SERVICES</li>
          <li className="notice-item">
            Adresse : 10. Rue de Penthievre, 75008 Paris
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LegalNotice;
