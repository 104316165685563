import React from 'react';
import Button from '../../Button';
import DataTable from '../../../data';
import TitleH2 from '../../Titles/TitleH2';
import TitleH3 from '../../Titles/TitleH3';
import TitleH4 from '../../Titles/TitleH4';
import './services.css';

import illustration from '../../../assets/images/my_bg_services.svg';

function Services() {
  return (
    <main>
      <div className="services">
        {/* services-first--container */}
        <div className="services-first--container">
          <div className="services--content presentation" data-aos="flip-left">
            <TitleH2>Comment puis-je vous aider ?</TitleH2>
            <p>
              ...Je vous propose une multitude de services, dans la mesure du
              possible, qui peuvent vous être utiles et vous aider dans la
              conception et la création de vos différents projets. Les tarifs
              des services, que je vous propose, sont affichés en dessous.
              J'intègre, transforme et donne vie aux maquettes de formats psd,
              figma, photoshop grâce aux technologies suivantes: Html5, Css3,
              Bootstrap5, tout en incluant des animations Vanilla JavaScript. Je
              développe des Api React, des Api Vue.js avec l'ensemble de leurs
              composants. Grâce au préprocesseur Sass, par exemple, je peux
              ajouter des fonctionnalités aux fichiers css des Apis. Je
              m'interesse à des réalisations du Back-End avec Php et MySql.
            </p>
            <p>
              {' '}
              Je crée et inslalle des sites Web Wordpress. Le choix du futur
              template de l'Api, la charte graphique, les textes et la
              photographie, tous ces éléments resteront propres à chaque
              personne porteuse d'un projet. Cela se décide, en amont, avant la
              construction du projet et pendant la préparation de la base des
              données de la futur Api. Autrement, j'inclus le montage des
              extensions possibles pour animer le futur site Internet.
              L'hébergement en ligne reste à la charge du client.
            </p>
            <p>
              Je crée et construits des petits morceaux de codes, cela peut
              prendre plusieurs formes différentes comme une bannière ou une
              animation JavaScript..etc. Mon rôle est de traduire la demande des
              clients en lignes de code informatique avec des technologies
              différentes.
            </p>
            <Button page="/contact">Me Contacter</Button>
          </div>

          <div className="services--image " data-aos="flip-right">
            <img src={illustration} alt="Une illustration Web" />
          </div>
        </div>
        {/* services-second--container */}
        <div className="services-second--container">
          <div className="services-prices--container">
            <TitleH3 className="title--honoray">Honoraires</TitleH3>
            <div className="services-honoray--container">
              {DataTable[0].honoray.length > 0 &&
                DataTable[0].honoray.map(honoray => {
                  return (
                    <div
                      key={honoray.id}
                      className="services-card--honoray"
                      data-aos="zoom-in-down"
                    >
                      <TitleH4 className="title-card--honoray">
                        {honoray.name}{' '}
                      </TitleH4>
                      <p>: {honoray.price}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* services-third--container */}
        <div className="services-third--container">
          <div className="services--skills">
            <h3 className="title--services">Services</h3>

            <div className="services-skills--container">
              {DataTable[0].services.length > 0 &&
                DataTable[0].services.map(service => {
                  return (
                    <div
                      key={service.id}
                      className="skills--item"
                      data-aos="flip-left"
                    >
                      <TitleH4 className="title-skills--item">
                        {service.name}
                      </TitleH4>
                      <p>{service.price}</p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default React.memo(Services);
