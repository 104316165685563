import React, { useRef, useState, useEffect } from 'react';
import './footer.css';

function Footer() {
  const [scrollTo, setScrollToTop] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const onScrollHandler = function () {
      if (
        Math.round(window.scrollY) >
        document.documentElement.offsetHeight / 3
      ) {
        buttonRef.current.classList.add('active');
      } else {
        buttonRef.current.classList.remove('active');
        setScrollToTop(false);
      }
    };

    window.addEventListener('scroll', onScrollHandler);
    window.scroll({ top: 0, behavior: 'smooth' });

    return () => {
      window.removeEventListener('scroll', onScrollHandler);
    };
  }, [scrollTo]);

  return (
    <footer className="footer-container">
      <ul className="footer-items-group">
        <li className="footer-item">
          &copy;2024 MCNEAPOLIS WEB Mohamed OUAILI
        </li>
        <li className="footer-item">&nbsp; Développeur Intégrateur Web</li>
        <li className="footer-item">&nbsp;Siret : 880 540 521 00015</li>
        <li className="footer-item">Tél : (003) 06 49 33 18 81</li>
        <li className="footer-item"></li>
      </ul>
      <button ref={buttonRef} className="scroll-top" onClick={setScrollToTop}>
        <i className="fas fa-chevron-up"></i>
      </button>
    </footer>
  );
}

export default Footer;
