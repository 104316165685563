const DataTable = [
  {
    skills: [
      {
        name: 'Html5',
        description: '100%',
        color: '#fb8500',
      },
      {
        name: 'Css3',
        description: '100%',
        color: '#3b80b3',
      },
      {
        name: 'Vanilla JavaScript',
        description: '100%',
        color: '#f9e64f',
      },
      {
        name: 'React.js',
        description: '95%',
        color: '#61dafb',
      },
      {
        name: 'Vue.js',
        description: '85%',
        color: '#42d392',
      },
      {
        name: 'Php',
        description: '85%',
        color: '#7a86b8',
      },
      {
        name: 'MySql',
        description: '75%',
        color: '#3E6E93',
      },
      {
        name: 'Bootstrap',
        description: '100%',
        color: '#6f42c1',
      },
      {
        name: 'Photoshop',
        description: '100%',
        color: '#2962ff',
      },
      {
        name: 'Indesign',
        description: '90%',
        color: '#c9252d',
      },
      {
        name: 'Figma',
        description: '85%',
        color: '#0acf83',
      },
    ],
    services: [
      {
        id: 1,
        name: 'Single Page',
        price: '138€',
      },
      {
        id: 2,
        name: 'Intégration Bootstrap',
        price: '135€',
      },
      {
        id: 3,
        name: 'Animations JavaScript',
        price: '138€',
      },
      {
        id: 4,
        name: 'Site web vitrine',
        price: 'Sur Demande',
      },
      {
        id: 5,
        name: 'Site Wordpress',
        price: 'Sur Demande',
      },
      {
        id: 6,
        name: "Intégration d'un morceau de code",
        price: '75€',
      },
      {
        id: 7,
        name: "Création d'une Bannière",
        price: '125€',
      },
      {
        id: 8,
        name: "Développement d'une Api React.js",
        price: 'Sur Demande',
      },
      {
        id: 9,
        name: "Développement d'une Api Vue.js",
        price: 'Sur Demande',
      },
    ],
    honoray: [
      {
        id: 1,
        name: 'Honoraire ',
        price: ' 38€/heure',
      },
      {
        id: 2,
        name: 'Taux Journalier moyen',
        price: ' 286€/jour',
      },
    ],
    curriculum: [
      {
        id: 1,
        content: 'Maîtrise de la Qualité en Projet Web',
        description: 'Opquast',
      },
      {
        id: 2,
        content: 'Formation Développeur Intégrateur Web',
        description: 'Skill and You',
      },
      {
        id: 3,
        content: 'Diplôme de Technicien Supérieur',
        description: 'Dava Grenoble (Tourisme)',
      },
      {
        id: 4,
        content: 'Diplôme de Langue et Lettres Françaises',
        description: 'IBLV Institut Tunis I',
      },
      {
        id: 5,
        content: 'Diplôme de Baccalaureat',
        description: 'Lycée Technique Nabeul',
      },
      {
        id: 6,
        content: 'Attestation en Langue Allemande',
        description: 'IBLV Tunis I',
      },
    ],
  },
];

export default DataTable;
