import React, { useState, useEffect } from 'react';
import { Link, scroller } from 'react-scroll';
import 'animate.css';
import './header.css';
import TitleH1 from '../Titles/TitleH1';

function Header() {
  const [offset, setOffset] = useState(false);

  useEffect(() => {
    setOffset(true);
    const scrollHome = () => {
      scroller.scrollTo('home', {
        smooth: true,
        containeriD: 'home',
        offset: 0,
      });
      window.addEventListener('scroll', scrollHome);
    };

    return () => {
      window.removeEventListener('scroll', scrollHome);
    };
  }, [offset]);

  return (
    <header id="header">
      <div className="header--logo">
        <div className="header--image">
          <div className="logo">
            <img
              src={require('../../assets/logo/logo.png')}
              alt="logo Mcneapolis Web"
            />
            <h2>MCNEAPOLIS WEB</h2>
          </div>
        </div>
        <div className="header--infos animate__animated animate__backInDown ">
          <TitleH1 className="header-big--title ">
            Bonjour, je suis <span>Mohamed</span>
            <p>Développeur Web Front-End</p>
            <Link className="header-btn--infos" to="home" onClick={setOffset}>
              Plus d'infos
            </Link>
          </TitleH1>
        </div>
        <img
          className="badge--image"
          src={require('../../assets/logo/badge_confirme.svg').default}
          alt=""
        />
      </div>
    </header>
  );
}

export default Header;
