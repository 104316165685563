import React from 'react';
import Layout from '../Layout';
import Footer from '../Footer';

function App() {
  return (
    <div className="App">
      <Layout />
      <Footer />
    </div>
  );
}

export default App;
