import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import TitleH2 from '../Titles/TitleH2';
import './navbar.css';

import logo from '../../assets/logo/logo.png';

function Navbar() {
  const [displayMenu, setDisplayMenu] = useState(false);

  const displayToggleMenu = function () {
    setDisplayMenu(!displayMenu);
  };

  const scrollToTop = function () {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleResize = function () {
      //do magic for resize
      setDisplayMenu(false);
    };
    //add event listener
    window.addEventListener('resize', handleResize);
    return () => {
      //remove event listener
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="nav nav-navbar">
      <div className="navbar--logo">
        <Link to="/" onClick={scrollToTop}>
          <img src={logo} alt="Logo Mcneapolis web" />
        </Link>
        <TitleH2>MCNEAPOLIS</TitleH2>
      </div>
      <div className="menu" onClick={displayToggleMenu}>
        <ul className={`navbar--links ${displayMenu ? 'toggle--menu' : ''}`}>
          <li className="navbar--item slideItemUp1">
            <NavLink className="navbar--link" to="/" onClick={scrollToTop}>
              Accueil
            </NavLink>
          </li>
          <li className="navbar--item slideItemUp2">
            <NavLink
              className="navbar--link"
              to="/services"
              onClick={scrollToTop}
            >
              Services
            </NavLink>
          </li>
          <li className="navbar--item slideItemUp3">
            <NavLink
              className="navbar--link"
              to="/portfolio"
              onClick={scrollToTop}
            >
              Portfolio
            </NavLink>
          </li>
          <li className="navbar--item slideItemUp4">
            <NavLink
              className="navbar--link"
              to="/contact"
              onClick={scrollToTop}
            >
              Contact
            </NavLink>
          </li>{' '}
        </ul>
      </div>
      <div className="nav-navbar--contact">
        <NavLink to="tel:0649331881">
          {' '}
          <i className="fa-solid fa-phone"></i>(0033) 06 49 33 18 81
        </NavLink>
      </div>
      <div
        className={`${displayMenu ? 'navbar--cross' : 'navbar--btn'}`}
        onClick={displayToggleMenu}
      ></div>
    </nav>
  );
}

export default Navbar;
