import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ children, page }) => {
  const goToTheTopPage = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Link to={page} className="scrollBTN" onClick={goToTheTopPage}>
      {children}
    </Link>
  );
};

export default Button;
