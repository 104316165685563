import React, { Fragment } from 'react';
import Header from '../../Header';
import Button from '../../Button';
import TitleH2 from '../../Titles/TitleH2';
import './home.css';

import profile from '../../../assets/images/profile.png';

import html from '../../../assets/icons-web/html5.svg';
import css from '../../../assets/icons-web/css3.svg';
import javascript from '../../../assets/icons-web/javascript.svg';
import react from '../../../assets/icons-web/react.svg';
import vue from '../../../assets/icons-web/vue.svg';
import indesign from '../../../assets/icons-web/indesign.svg';
import photoshop from '../../../assets/icons-web/photoshop.svg';
import figma from '../../../assets/icons-web/figma.svg';
import php from '../../../assets/icons-web/php.png';
import mysql from '../../../assets/icons-web/MySql.png';
import sass from '../../../assets/icons-web/sass.svg';
import bootstrap from '../../../assets/icons-web/bootstrap.svg';
import wordpress from '../../../assets/icons-web/wordpress.svg';

function Home() {
  return (
    <Fragment>
      <Header />
      <div className="home" id="home">
        <div className="home-container">
          <div className="home-first--container">
            <div className="home-first--content" data-aos="zoom-out-down">
              <TitleH2>Si je dois me présenter...</TitleH2>
              <p>
                Je suis Développeur Front-End, Intégrateur Web et Freelance.
                Passionné par mon métier, je suis déterminé à développer de
                nouvelles expériences utilisateur (UI : User Interface, UX :
                User Experience) et construire de nouveaux projets Web. Je suis
                formé à la programmation informatique et langages de
                programmation Web à l'ESECAD du groupe Skill And You qui est le
                leadeur européen de la formation en ligne. Je suis certifié et
                confirmé OPQUAST dans la Maîtrise de la Qualité en Projet Web
                depuis. Je m'interesse aux nouvelles technologies du Web, aux
                nouveaux langages de programmation, à la conception des Api et
                sites Internet et à tout ce qui touche au graphisme et au design
                des projets. J'intègre et développe des projets en différentes
                technologies. Je me forme régulièrement en suivant des
                formations soit payantes, soit gratuites. Je suis ouvert à
                toutes les demandes, les propositions et les collaborations avec
                de nouveaux développeurs, des designers et des graphistes pour
                des projets ambitieux.
              </p>
              <Button page="/portfolio">Mon Portfolio</Button>
            </div>
            <div className="home-second--content">
              <img
                className="profile"
                data-aos="zoom-out-up"
                src={profile}
                alt="Désigner un projet"
              />
            </div>
          </div>
        </div>
        <div className="home--second-container">
          <div className="home-skills--container">
            <div className="home-skill">
              <img src={html} alt="Html5" />
            </div>
            <div className="home-skill">
              <img src={css} alt="Css3" />
            </div>
            <div className="home-skill">
              <img src={javascript} alt="JavaScript" />
            </div>
            <div className="home-skill">
              <img src={react} alt="React.js" />
            </div>
            <div className="home-skill">
              <img src={vue} alt="Vue.js" />
            </div>
            <div className="home-skill">
              <img src={indesign} alt="Indesign" />
            </div>
            <div className="home-skill">
              <img src={photoshop} alt="Photoshop" />
            </div>
            <div className="home-skill">
              <img src={figma} alt="Figma" />
            </div>
            <div className="home-skill">
              <img src={php} alt="Php" />
            </div>
            <div className="home-skill">
              <img src={mysql} alt="MySql" />
            </div>
            <div className="home-skill">
              <img src={sass} alt="MySql" />
            </div>
            <div className="home-skill">
              <img src={bootstrap} alt="MySql" />
            </div>
            <div className="home-skill">
              <img src={wordpress} alt="MySql" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Home;
