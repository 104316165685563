import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';

import Portfolio from '../Pages/Portfolio';
import Services from '../Pages/Services';
import Contact from '../Pages/Contact';
import Home from '../Pages/Home';
import Navbar from '../Menu';
import Error from '../Pages/Error';
import LegalNotice from '../Pages/LegalNotice';

function Layout() {
  return (
    <Fragment>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentions-legales" element={<LegalNotice />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </Fragment>
  );
}

export default Layout;
