import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import TitleH2 from '../../Titles/TitleH2';
import TitleH5 from '../../Titles/TitleH5';
import './contact.css';

import bgContact from '../../../assets/images/contact-us.svg';
import linkedin from '../../../assets/icons/linkedin.svg';
import github from '../../../assets/icons/github.svg';
import skype from '../../../assets/icons/skype.png';
import malt from '../../../assets/icons/malt.svg';

function Contact() {
  return (
    <Fragment>
      <div className="contact">
        <div className="contact-first--container">
          <div className="contact-first--infos">
            <TitleH2>Comment me contacter ?</TitleH2>
            <p>
              Pour la réaliation d'un site Internet, une Api Web, un projet
              d'intégration en Html5, Css3, Bootstrap, JavaScript, la
              réalisation d'un site Wordpress ou pour une collaboration,
              n'hésitez pas à me contacter. Je suis joignable toute la semaine
              par téléphone. Si je ne vous répondais pas, vous pourrez me
              laisser un message ou un sms pour présenter votre création de
              projet. Sinon, vous pourrez, également, m'écrire via mon adresse
              émail en cliquant directement sur le lien qui se trouve en
              dessous.{' '}
            </p>
          </div>
          <div className="contact-second--infos">
            <Link to="tel:0649331881">
              <i className="fa-solid fa-phone"></i>(0033) 06 49 33 18 81
            </Link>
            <Link to="mailto:ouaili.med@orange.fr">
              <i className="fa-sharp fa-regular fa-at"></i>
              ouaili.med@orange.fr
            </Link>
          </div>
          <TitleH5 className="social--small-title">
            On se retrouve sur les réseaux sociaux :{' '}
          </TitleH5>
          <div className="contact-social--medias">
            <div>
              <Link
                to="https://www.linkedin.com/in/mcneapolisweb/"
                target="_blanck"
              >
                <img src={linkedin} alt="Le réseau professionnel Linkedin" />
              </Link>
            </div>
            <div>
              <Link to="https://www.malt.fr/profile/medouaili" target="_blanck">
                <img src={malt} alt="La plateforme des freelanceurs Malt" />
              </Link>
            </div>
            <div>
              <Link to="https://www.skype.com/fr/" target="_blanck">
                <img src={skype} alt="Le réseau de communication Skype" />
              </Link>
            </div>
            <div>
              <Link to="https://github.com/mouaili" target="_blanck">
                <img
                  src={github}
                  alt="Le réseau social des développeurs Github"
                />
              </Link>
            </div>
          </div>
          <div className="contact-notice">
            <ul>
              <li className="contact--notice-link">
                <span> &#9752;</span>
                <Link
                  to="/mentions-legales"
                  onClick={() =>
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                  }
                >
                  Mentions Légales
                </Link>
                <span>&#9752;</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-second--container">
          <div className="contact-image--container">
            <img src={bgContact} alt="fond de page Contact" />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Contact;
